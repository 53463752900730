import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, ParamMap } from '@angular/router';
import { StationService } from '../service/station.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'; // add this 1 of 4
import Swal from 'sweetalert2';
import { switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-listemp',
  templateUrl: './listemp.component.html',
  styleUrls: ['./listemp.component.css']
})
export class ListempComponent implements OnInit {
station : any= null;
stationid: any;
avis:any;
  constructor(private stationservice:StationService, private router: Router,public route: ActivatedRoute) { }
  getstation() {
    this.stationservice.getStationById(this.stationid).subscribe((data: {}) => {
      this.station=data;
    });
  }
  firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff = dayOfWeek >= firstDayOfWeekIndex ?
            dayOfWeek - firstDayOfWeekIndex :
            6 - dayOfWeek
    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(14,0,0,0)
    return firstDayOfWeek
}
  goToEmploye(id,nom){
    let options1 = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    };
    let lastMonday = this.firstDayOfWeek(new Date(), 1);
    let start=new Intl.DateTimeFormat("fr-FR",options1).format(lastMonday)
    let nextmonday=lastMonday
    nextmonday.setDate(nextmonday.getDate() + 7);
    nextmonday.setHours(13)
    nextmonday.setMinutes(59)
    let end=new Intl.DateTimeFormat("fr-FR",options1).format(nextmonday)
    this.avis=localStorage.getItem(id);
    if((this.avis)&& (this.avis>start) && (this.avis<end)){
      Swal.fire({
        icon: 'error',
        title: 'Désolé!',
        text: 'Vous avez déjà distribué un smile à '+nom+' cette semaine.',
      })
    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "id":id ,
        }
      };
      this.router.navigate(["/employe"], navigationExtras);
    }

  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.stationid= params["id"];
    });
    this.getstation();
  }

}
