import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'
import {BASE_URL} from "./const"

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  apiURL = BASE_URL;

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/*',
      'Access-Control-Allow-Origin' : '*',
       Accept : 'application/pdf',
       observe : 'response',
      'Access-Control-Allow-Credentials' : 'true',
      'Authorization':'authkey',

    })

  }
  postAvis( avis,nom,email,tel,dateNaiss,IsSms,IsEmail): Observable<any> {
    const  headers = new Headers();
    headers.append('content-type', 'application/json');


  const data = new FormData();
  data.append('avis', avis);
  data.append('email',  email);
  data.append('nom', nom);
  data.append('tel', tel);
  data.append('date', dateNaiss);
  data.append('sendsms',  IsSms);
  data.append('sendemail',  IsEmail);
 // data.append('datepassage',  datepassage);

  data.append('content-type', 'application/json');

    let body=JSON.stringify({
      'avis':  avis,
      'email':  email,
      'nom': nom,
      'tel': tel,
      'date': dateNaiss,
      'sendsms':  IsSms,
      'sendemail':  IsEmail,
   //   'datepassage':  datepassage,
    });
     return this.http.post<any>(this.apiURL + 'pays/postAvis', data).pipe();

  }
}
