import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'
import {BASE_URL} from "./const"
@Injectable({
  providedIn: 'root'
})
export class EmployeService {

  apiURL = BASE_URL;

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/*',
      'Access-Control-Allow-Origin' : '*', 
       Accept : 'application/pdf',
       observe : 'response',
      'Access-Control-Allow-Credentials' : 'true',
      'Authorization':'authkey',

    })

  }  
  getEmployeById(id): Observable<any> {
    return this.http.get(this.apiURL + 'pays/getEmployeById/'+id).pipe();  
    }
    Noter( idemp,note): Observable<any> { 
      const  headers = new Headers();
      headers.append('content-type', 'application/json');
      
  
    const data = new FormData();
    data.append('idemp', idemp);
    data.append('note', note);
    data.append('content-type', 'application/json');
      let body=JSON.stringify({ 
        'idemp': idemp,
        'note': note
      });
       return this.http.post<any>(this.apiURL + 'pays/Noter', data).pipe();
    }
}
