import { Component, OnInit,Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import {StationService} from '../../service/station.service';
import * as moment from 'moment';
import { PaysService } from 'src/app/service/pays.service';
@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {
  comment:any="" ;
  station : any =null;
  stationId : any;
  note:any;
  IsCheckNote=true;
  result:  any;
  avis:any;
  constructor(private stationService:StationService,private paysservice: PaysService, private router: Router,public route: ActivatedRoute,public dialog: MatDialog) { }
  infoPays(){
    this.paysservice.getPaysById().subscribe((data: {}) => {
      if (data['parametre']) {
        let parametre =  data['parametre']
        localStorage.setItem('textgris', parametre.textgris)
        localStorage.setItem('textgris1', parametre.textgris1)
        localStorage.setItem('textrouge', parametre.textrouge)
        localStorage.setItem('image', parametre.image)
      }
    });
  }
  getStation() {
    this.stationService.getStationById(this.stationId).subscribe((data: {}) => {
      this.station=data;
    });
  }
  noterstation(note){
    this.note=note;
    this.IsCheckNote=false;
  }


  noterpompiste(note){
    this.note=note;
    this.IsCheckNote=false;
  }
  valider(){

    let options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZone: 'Indian/Reunion',
      format: 'dd/mm/yyyy hh:mm:ss'
    };

    let datenow = new Intl.DateTimeFormat("fr-FR",options).format(new Date())

    this.stationService.noterStation(this.stationId,this.note,this.comment).subscribe((data: {}) => {
      this.result=data;
      localStorage.setItem(this.stationId,datenow)
      Swal.fire({
        title: '<strong></strong>',
        html:
         // localStorage.getItem('textgris')+" "+localStorage.getItem('textrouge')+" "+localStorage.getItem('textgris1')
         'Tentez de gagner de nombreux cadeaux en vous inscrivant ici',
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          //'Je tente ma chance'
          'Participez ',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText:
          'Non Merci',
        cancelButtonAriaLabel: 'Thumbs down'
      }).then((result) => {
        if (result.value) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
              "avis":this.result ,
            }
          };
          this.router.navigate(["/note"], navigationExtras);
        }
        else{
          this.router.navigate(["/"]);
        }
      })
    });




  }
  firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
        dayOfWeek - firstDayOfWeekIndex :
        6 - dayOfWeek
    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(14,0,0,0)
    return firstDayOfWeek
  }
  ngOnInit() {
    let options1 = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    };
    let lastMonday = this.firstDayOfWeek(new Date(), 1);
    let startString=new Intl.DateTimeFormat("fr-FR",options1).format(lastMonday)
    let nextmonday=lastMonday
    nextmonday.setDate(nextmonday.getDate() + 7);
    nextmonday.setHours(13)
    nextmonday.setMinutes(59)
    let endString=new Intl.DateTimeFormat("fr-FR",options1).format(nextmonday)
    this.route.queryParams.subscribe(params => {
      this.stationId= params["id"];
    });
    let dateString=localStorage.getItem(this.stationId);
    this.avis = moment(dateString, 'DD/MM/YYYY, HH:mm:ss');
    let start = moment(startString, 'DD/MM/YYYY, HH:mm:ss');
    let end = moment(endString, 'DD/MM/YYYY, HH:mm:ss');
    if((this.avis)&& (this.avis>start) && (this.avis<end)){
      this.router.navigate(["/"]);
    }
    else{
      if(!localStorage.getItem('textgris')){
        this.infoPays();
      }
      this.getStation();
    }

  }


}
