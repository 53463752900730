import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaysComponent } from './component/pays/pays.component';
import { ListempComponent } from './listemp/listemp.component';
import { EmployeComponent } from './employe/employe.component';
import { NoteComponent } from './note/note.component';
import { ResultComponent } from './result/result.component';
import {StationComponent} from './component/station/station.component';


const routes: Routes = [
  { path: '', component: PaysComponent },
  { path: 'listemp', component: ListempComponent },
  { path: 'station', component: StationComponent },
  { path: 'employe', component: EmployeComponent },
  { path: 'note', component: NoteComponent },
  { path: 'end', component: ResultComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
