import { Component, OnInit, OnDestroy } from '@angular/core';
import { faCheck, faDumpster } from '@fortawesome/free-solid-svg-icons';
import { PaysService } from 'src/app/service/pays.service';
import { Router, NavigationExtras } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment'; // add this 1 of 4
import { DatePipe} from '@angular/common';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { format } from 'url';
import {StationService} from '../../service/station.service';


@Component({
  selector: 'app-pays',
  templateUrl: './pays.component.html',
  styleUrls: ['./pays.component.css']
})
export class PaysComponent implements OnInit {
  pays: any = [];
  stations: any = [];
  selectstations: any = [];
  gagnants: any = [];
  parametre: any = [];
  btnPays = '';
  btnStation = 'choisir une station';
  stationId = null;
  station = null;
  avis = null;
  stationname = null; 
  IsCheckPays = false;
  search = null;
  width:any;

  constructor(public datepipe: DatePipe,private paysservice: PaysService,private stationservice: StationService, private router: Router) { }
  getPays() {
    this.pays = [];
    this.paysservice.getPaysById().subscribe((data: {}) => {

      this.pays = data;
      this.btnPays = 're';
      this.stations = data['stations']
      this.selectstations = data['stations']
      this.gagnants = data['gagnants']
      let options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        timeZone: 'Indian/Reunion',
        timeZoneName: 'short'
      };
      let options1 = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
      };
   /*

      //first day of week with time 14:00
      let datestart = this.firstDayOfWeek(new Date(), 1, 14, 0);
      let start=new Intl.DateTimeFormat("fr-FR",options1).format(datestart)
      //first day of week with time 14:30
      let dateend = this.firstDayOfWeek(new Date(), 1, 14, 30);
      let end=new Intl.DateTimeFormat("fr-FR",options1).format(dateend)
      let cadeau = localStorage.getItem('cadeau')
      let datenow = new Date().toLocaleString("fr-FR", { timeZone: "Indian/Reunion" })

      if (!cadeau) {

        if ((datenow >start)&&(datenow <end)) {
        Swal.fire({
          title: '',
          html:
            '<label style="padding-bottom: 10px;padding-top: 18px;font-size: 15px;font-weight: 500;font-family: arial;"> Les gagnants du tirage au sort de la semaine dernière </label>' +
            '<img src="assets/images/1.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #C4760F;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 1].client.nom +
            "</label>" + '<br>' +
            '<img src="assets/images/2.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #7B7B7B;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 2].client.nom +
            "</label>" + '<br>' +
            '<img src="assets/images/3.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #DA0637;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 3].client.nom +
            "</label>" + '<br>',
          showCloseButton: true,
          showConfirmButton: false,
          imageUrl: 'assets/images/bg-gagnants.jpg',
          imageWidth: 240,
          imageAlt: 'gagnant image',
        })
        localStorage.setItem('cadeau',datenow);
        }
      }
      //if datenow is fist day of week and between 14:00 and 14:30
      else if ((cadeau >start)&&(cadeau <end)) {
        Swal.fire({
          title: '',
          html:
            '<label style="padding-bottom: 10px;padding-top: 18px;font-size: 15px;font-weight: 500;font-family: arial;"> Les gagnants du tirage au sort de la semaine dernière </label>' +
            '<img src="assets/images/1.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #C4760F;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 1].client.nom +
            "</label>" + '<br>' +
            '<img src="assets/images/2.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #7B7B7B;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 2].client.nom +
            "</label>" + '<br>' +
            '<img src="assets/images/3.png" style="padding-right: 10px;"/><label style="padding-bottom: 10px;font-size: 18px;color: #DA0637;text-transform: capitalize;">' +
            this.gagnants[this.gagnants.length - 3].client.nom +
            "</label>" + '<br>',
          showCloseButton: true,
          showConfirmButton: false,
          imageUrl: 'assets/images/bg-gagnants.jpg',
          imageWidth: 240,
          imageAlt: 'gagnant image',
        })

        localStorage.setItem('cadeau', datenow);
      }
      */

      if (data['parametre']) {

        this.IsCheckPays = true
        this.parametre =  data['parametre']
        localStorage.setItem('textgris', this.parametre.textgris)
        localStorage.setItem('textgris1', this.parametre.textgris1)
        localStorage.setItem('textrouge', this.parametre.textrouge)
        localStorage.setItem('image', this.parametre.image)
      }
    });
  }
  checkpays(paysname, stations, parametre) {
    this.btnPays = paysname;
    this.btnStation = 'choisir une station';
    this.stations = stations
    if (parametre) {
      this.IsCheckPays = true
      this.parametre = parametre
      localStorage.setItem('textgris', parametre.textgris)
      localStorage.setItem('textgris1', parametre.textgris1)
      localStorage.setItem('textrouge', parametre.textrouge)
      localStorage.setItem('image', parametre.image)

    }
    else {
      this.IsCheckPays = false
      this.parametre = null
    }
  }
  goToStation() {
    let options1 = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      format: 'dd/mm/yyyy hh:mm:ss'
    };
    let lastMonday = this.firstDayOfWeek1(new Date(), 1);
    let startString=new Intl.DateTimeFormat("fr-FR",options1).format(lastMonday)
    let nextmonday=lastMonday
    nextmonday.setDate(nextmonday.getDate() + 7);
    nextmonday.setHours(13)
    nextmonday.setMinutes(59)
    let endString=new Intl.DateTimeFormat("fr-FR",options1).format(nextmonday)
    let dateString = localStorage.getItem(this.stationId);
    this.avis = moment(dateString, 'DD/MM/YYYY, HH:mm:ss');
    let start = moment(startString, 'DD/MM/YYYY, HH:mm:ss');
    let end = moment(endString, 'DD/MM/YYYY, HH:mm:ss');
    //this.avis = momentVariable.format('DD/MM/YYYY, HH:mm:ss');
    if((this.avis) && (this.avis>start) && (this.avis<end)){
      this.stationservice.getStationById(this.stationId).subscribe((data: {}) => {
        this.station=data
        Swal.fire({
          icon: 'error',
          title: 'Désolé!',
          text: 'Vous avez déjà distribué un smile à '+this.station.nom +' cette semaine.',
        })
      });

    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': this.stationId,
        }
      };
      this.router.navigate(["/station"], navigationExtras);
    }



  }
  firstDayOfWeek(dateObject, firstDayOfWeekIndex, h, m) {

    const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
        dayOfWeek - firstDayOfWeekIndex :
        6 - dayOfWeek
    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(h, m, 0, 0)
    return firstDayOfWeek
  }
  firstDayOfWeek1(dateObject, firstDayOfWeekIndex) {

    const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
        dayOfWeek - firstDayOfWeekIndex :
        6 - dayOfWeek
    firstDayOfWeek.setDate(dateObject.getDate() - diff)
    firstDayOfWeek.setHours(14,0,0,0)
    return firstDayOfWeek
  }
  ngOnInit() {
    this.width = window.innerWidth;
    this.getPays();

    //this.paysservice.getAllPays().subscribe((data) => {
    //});
  }
  onKey(value) {
    this.selectstations = [];
    this.selectSearch(value);
  }
  selectSearch(value: string) {
    let filter = value.toLowerCase();
    for (let i = 0; i < this.stations.length; i++) {
      let option = this.stations[i];
      if (option.name.toLowerCase().indexOf(filter) >= 0) {
        this.stations.push(option);
      }
    }
  }
}
