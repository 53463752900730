import { Component, OnInit,Inject } from '@angular/core';
import { EmployeService } from '../service/employe.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import * as moment from 'moment'; // add this 1 of 4
@Component({
  selector: 'app-employe',
  templateUrl: './employe.component.html',
  styleUrls: ['./employe.component.css']
})
export class EmployeComponent implements OnInit {
employe : any =null;
employeid : any;
note:any;
IsCheckNote=true;
result:  any;
avis:any;
  constructor(private empservice:EmployeService, private router: Router,public route: ActivatedRoute,public dialog: MatDialog) { }
  getemploye() {
    this.empservice.getEmployeById(this.employeid).subscribe((data: {}) => {
      this.employe=data;
    });
  }
noterpompiste(note){
    this.note=note;
    this.IsCheckNote=false;
}
valider(){

  let options = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    timeZone: 'Indian/Reunion',
    timeZoneName: 'short'
  };
  let datenow = new Intl.DateTimeFormat("fr-FR",options).format(new Date())
  this.empservice.Noter(this.employeid,this.note).subscribe((data: {}) => {
    this.result=data;
    localStorage.setItem(this.employeid,datenow)
  });


  Swal.fire({
    title: '<strong></strong>',
    html:
    localStorage.getItem('textgris')+" "+localStorage.getItem('textrouge')+" "+localStorage.getItem('textgris1'),
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText:
      'Je tente ma chance',
    confirmButtonAriaLabel: 'Thumbs up, great!',
    cancelButtonText:
      'Non Merci',
    cancelButtonAriaLabel: 'Thumbs down'
  }).then((result) => {
    if (result.value) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "avis":this.result ,
        }
      };
      this.router.navigate(["/note"], navigationExtras);
    }
    else{
      this.router.navigate(["/"]);
    }
  })

}
firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

  const dayOfWeek = dateObject.getDay(),
      firstDayOfWeek = new Date(dateObject),
      diff = dayOfWeek >= firstDayOfWeekIndex ?
          dayOfWeek - firstDayOfWeekIndex :
          6 - dayOfWeek
  firstDayOfWeek.setDate(dateObject.getDate() - diff)
  firstDayOfWeek.setHours(14,0,0,0)
  return firstDayOfWeek
}
  ngOnInit() {
    let options1 = {
      year: 'numeric', month: 'numeric', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    };
    let lastMonday = this.firstDayOfWeek(new Date(), 1);
    let start=new Intl.DateTimeFormat("fr-FR",options1).format(lastMonday)
    let nextmonday=lastMonday
    nextmonday.setDate(nextmonday.getDate() + 7);
    nextmonday.setHours(13)
    nextmonday.setMinutes(59)
    let end=new Intl.DateTimeFormat("fr-FR",options1).format(nextmonday)
    this.route.queryParams.subscribe(params => {
      this.employeid= params["id"];
    });
    this.avis=localStorage.getItem(this.employeid);

    if((this.avis)&& (this.avis>start) && (this.avis<end)){
      this.router.navigate(["/"]);
    }
    else{
      this.getemploye();
    }

  }


}
