import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormGroup, FormControl,FormsModule,NgForm, Validators } from '@angular/forms';
import { Location, DatePipe } from '@angular/common';
import { NoteService } from '../service/note.service';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd,Event, NavigationStart } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import {formatDate } from '@angular/common';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS,MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {NgModule} from '@angular/core';
import {MatFormFieldControl} from '@angular/material';
import { NgxMatDateAdapter, NgxMatTimepickerModule } from 'ngx-mat-datetime-picker';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: NgxMatDateAdapter,
      useClass: NoteComponent,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
  ],

})
@NgModule({
  imports: [NgxMaterialTimepickerModule.setLocale('fr-FR')]
})
export class NoteComponent implements OnInit {
  public minDate: Date = new Date ("05/07/2017 2:00 AM");
  public maxDate: Date = new Date ("05/27/2017 11:00 AM");
  public dateValue: Date = new Date ("05/16/2017 5:00 AM");
name:any="" ;
email:any="" ;
date:any;
//datepassage:any;
tel:any;
IsSms:any;
IsEmail:any;
general:any;
use:any;
note:any;
idemp:any;
avis:any;
startDate = new Date(1950, 0, 1);
isSubmitted: boolean = false;
  constructor(private noteservice:NoteService,private router: Router,public route: ActivatedRoute,private _adapter: DateAdapter<any>) {


  }
validation(){
  this.isSubmitted = true;
  var datePipe = new DatePipe('en-US');
  this.date=datePipe.transform(this.date, 'yyyy-MM-dd')
 // this.datepassage=datePipe.transform(this.datepassage, 'yyyy-MM-dd h:mm:ss')
  this.noteservice.postAvis( this.avis,this.name,this.email,this.tel,this.date,this.IsSms,this.IsEmail ).subscribe((data: {}) => {
    if (data == true){
      let navigationExtras: NavigationExtras = {
        queryParams: {

          "res":true ,
        }
      };
      this.router.navigate(["/end"], navigationExtras);
    }
    else{
      let navigationExtras: NavigationExtras = {
        queryParams: {
          "res":false ,
        }
      };
      this.router.navigate(["/end"], navigationExtras);
    }
  });

}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.avis= params["avis"];
    });
    if(localStorage.getItem('nom')) this.name= localStorage.getItem('nom')
    if(localStorage.getItem('datenaiss')) {
      var datePipe = new DatePipe('en-US');
      this.date=datePipe.transform(localStorage.getItem('date'), 'yyyy-MM-dd')
    }
    if(localStorage.getItem('email')) this.email=localStorage.getItem('email')
    if(localStorage.getItem('tel')) this.tel=localStorage.getItem('tel')
    if(localStorage.getItem('issms'))this.IsSms=localStorage.getItem('issms')
    if(localStorage.getItem('isemail')) this.IsEmail=localStorage.getItem('isemail')
   /*
    this.router.events.subscribe( (event: Event) => {


      if (event instanceof NavigationEnd) {
        Swal.fire({
          title: '',
          text: 'Voulez vous quitté?',
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText:
            ' OUI',
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonText:
            'NON',
          cancelButtonAriaLabel: 'Thumbs down'
        }).then((result) => {
          if (result.value) {
           if(this.name) localStorage.setItem('nom',this.name)
           if(this.date)localStorage.setItem('datenaiss',this.date)
           if(this.email) localStorage.setItem('email',this.email)
           if(this.tel) localStorage.setItem('tel',this.tel)
           if(this.IsSms)localStorage.setItem('issms',this.IsSms)
           if(this.IsEmail) localStorage.setItem('isemail',this.IsEmail)
          this.router.navigate(["/"]);
          }
        })
      }
  });
  */
  }

}
