import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
res:boolean
image:any
textgris:any
textrouge:any
textgris1:any;
  constructor(public route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.res= (params["res"] =="true");
      this.textgris=localStorage.getItem('textgris')
      this.textgris1=localStorage.getItem('textgris1')

      this.textrouge=localStorage.getItem('textrouge')
      this.image=localStorage.getItem('image')

    });
  }

}
