import { Component } from '@angular/core';
import { NgModule }       from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd,Event } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'smilefront';

}
