import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators'
import {BASE_URL} from './const'
@Injectable({
  providedIn: 'root'
})
export class PaysService {

  apiURL = BASE_URL;

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/*',
      'Access-Control-Allow-Origin' : '*',
       Accept : 'application/pdf',
       observe : 'response',
      'Access-Control-Allow-Credentials' : 'true',
      'Authorization':'authkey',

    })

  }
  getPaysById(): Observable<any> {
    return this.http.get(this.apiURL + 'pays/getPaysById/1').pipe();
  }
  getAllPays(): Observable<any> {
    return this.http.get(this.apiURL + 'pays/getAllPays').pipe();
    }
  getGagnatsByPays(): Observable<any> {
    return this.http.get(this.apiURL + 'pays/getGagnatsByPays/1').pipe();
  }
  getStationsByPays(): Observable<any> {
    return this.http.get(this.apiURL + 'pays/getStationsByPays/1').pipe();
  }
}
